import "./testimonial.css"
import { useState } from "react"
import { TestimonialCard } from "./repeatComp"
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';

export function Testimonial(){
    const testElements = [{
        context:`Matured and well balanced. it requires a lot of wisdom to be like her. spot on with her predictions.`,
        name:"Ragavi",
        what:"Housewife"
    },
    {
        context:`I find her very knowledgeable and she provides clear answers. I am a repeat customer`,
        name:"Karthik",
        what:"CTO"
    },
    {
        context:`I got Meenatchiastro when I was going through a rough patch in my life. The personalized astrology reading I received was incredibly accurate and provided me with much-needed guidance.`,
        name:"Manigandan",
        what:"IT employee"
    },
    {
        context:`Meenatchiastro has become my go-to source for astrology readings. The astrologers here consistently provide accurate and insightful predictions. I've been amazed by their ability to foresee major life events with astonishing precision.`,
        name:"Ramesh",
        what:"Doctor"
    },
    {
        context:`What sets Meenatchiastro apart is not only their accuracy but also the professionalism and care they bring to their readings. The astrologers take the time to understand your concerns and provide detailed explanations for their insights.`,
        name:"Shuba",
        what:"Beautician"
    },
    {
        context:`Meenatchiastro has been a trusted companion on my life's journey. Whether it's matters of love, career, or personal growth, their astrologers have always been there to provide guidance and support`,
        name:"Nandhini",
        what:"Student"
    }
    ]

    const [homeTestIndex, setHomeTestIndex] = useState(0)
    const [testSlideMove, setTestSlideMove] = useState('translateX(0px)')
    const preHomeTest =()=>{
        if(homeTestIndex>0){
            setTestSlideMove(`translateX(-${260*(homeTestIndex-1)}px)`)
            setHomeTestIndex(homeTestIndex-1)
        }
    }
    const nextHomeTest =()=>{
        if(homeTestIndex<testElements.length-3){
            setTestSlideMove(`translateX(-${260*(homeTestIndex+1)}px)`)
            setHomeTestIndex(homeTestIndex+1)
        }
    }

    function SpinningDecor() {
        const { ref } = useParallax({ rotate: [0,180] }); // Use the useParallax hook
        return (
            <figure className="testDecor">
                <img ref={ref} src='./images/astroCircle.svg' alt='Astro'/>
            </figure>
        );
    }
    return(
        <section className='homeTestimonial'>
                <ParallaxProvider>
                    <SpinningDecor/>
                </ParallaxProvider>
                <aside>
                    <h3 className="testTitle">Customer Testimonial</h3>
                    <div className='testSlider'>
                        <div className='Slide' style={{transform:`${testSlideMove}`}}>
                            {testElements.map((test,key)=>(
                                <TestimonialCard
                                context={test.context}
                                name={test.name}
                                what={test.what}
                                key={key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='testSlideBtn'>
                        <figure onClick={preHomeTest} className={homeTestIndex===0?"arrowFade":"btnClick"}>
                            <img src='./images/back.svg' alt='Back'/>
                        </figure>
                        <figure onClick={nextHomeTest} className={homeTestIndex>=testElements.length-3?"arrowFade":"btnClick"}>
                            <img src='./images/next.svg' alt='Next'/>
                        </figure>
                    </div>
                </aside>
            </section>
    )
}