import { CallBtn } from '../components/repeatComp'
import './service.css'
import { ServiceCard } from '../components/repeatComp'
import { useEffect } from 'react'
import {Testimonial} from '../components/testimonial';

export default function Service(){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []); 

    const service = [
        {
            title:"Marriage Love Consultation",
            context:"Discover the path to lasting love and marital bliss with our Marriage/Love Consultation services. Our experienced astrologers delve deep into your birth charts, uncovering the cosmic forces that shape your romantic life.",
            image:"./images/Marriage & Love Consaultation.svg"
        },
        {
            title:"Career and Job Consultation",
            context:"Embark on a fulfilling career journey with our Career and Job Consultation services. Our skilled astrologers analyze your astrological charts to unveil your career potential and help you navigate the professional landscape.",
            image:"./images/job.svg"
        },
        {
            title:"Business Consultation",
            context:"Entrepreneurs, unleash the full potential of your ventures with our Business Consultation services. Our expert astrologers provide strategic insights and astrological guidance to enhance your business prospects.",
            image:"./images/buss.svg"
        },
        {
            title:"Health Consultation",
            context:"Prioritize your well-being with our Health Consultation services. Our astrologers analyze your birth charts to identify potential health challenges and offer preventive measures and remedies.",
            image:"./images/health.svg"
        },
        {
            title:"Childbirth & Child Name Consultation",
            context:"Discover the path to lasting love and marital bliss with our Marriage/Love Consultation services. Our experienced astrologers delve deep into your birth charts, uncovering the cosmic forces that shape your romantic life.",
            image:"./images/child.svg"
        },
        {
            title:"Horoscope Printing Consultation",
            context:"Discover the path to lasting love and marital bliss with our Marriage/Love Consultation services. Our experienced astrologers delve deep into your birth charts, uncovering the cosmic forces that shape your romantic life.",
            image:"./images/astro.svg"
        },
        {
            title:"Astrology Class",
            context:"Discover the path to lasting love and marital bliss with our Marriage/Love Consultation services. Our experienced astrologers delve deep into your birth charts, uncovering the cosmic forces that shape your romantic life.",
            image:"./images/class.svg"
        }
    ]
    return(
        <div className='wrapper'>
            <section className='serviceLanding'>
                <aside className='phoneBtn'>
                    <CallBtn/>
                </aside>
                <figure>
                    <img src='./images/lumos.png' alt='Astro'/>
                </figure>
                <header>
                    <h1>Meenatchi Astro<br/>Services</h1>                    
                </header>
            </section>
            <section className='serviceContentContainer'>
                {service.map((item,key)=>{
                    // setDirection(!direction)
                    return(
                        <ServiceCard
                        title={item.title}
                        context={item.context}
                        image={item.image}
                        index={key}
                        />
                )})}                
            </section>
            <Testimonial/>
        </div>
    )
}