import './home.css'
import { motion} from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { CallBtn, ActionBtn } from '../components/repeatComp';
import {Testimonial} from '../components/testimonial';
import ServiceTab from '../components/servicesTab';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';

export default function Home(){
    
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);
    
    // To Check Display
    const [parall, setParall] = useState()
    const navSelect = () =>{
        if(window.innerWidth<768){
            setParall(-50)
        }else{
            setParall(-25)
        }
    }
    useEffect(() => {
        if (window.innerWidth<768){
            setParall(-50)
        }else{
            setParall(-25)
        }
        window.addEventListener('resize', navSelect);
        window.scrollTo(0, 0);
        return () => {
        window.scrollTo(0, 0);
        window.removeEventListener('resize', navSelect);
        };
    }, []);

//  Framer Reveal FadeUp 
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView();
   
    useEffect(() => {
      setIsVisible(inView);
    }, [inView]);

// react-scroll-parallax Rotate
    function SpinningDecor() {
        const { ref } = useParallax({ rotate: [0,180] }); // Use the useParallax hook
        return (
            <figure>            
                <img ref={ref} className='outer' src='./images/decorOuter.svg' alt='Astro'/>    
                <img className='inner' src='./images/decorInner.svg' alt='Astro'/>
            </figure>
        );
    }

// react-scroll-parallax Rotate
    function ParallaxDecor() {
        const { ref } = useParallax({ speed: parall }); // Use the useParallax hook
        // -50 for mobile version
        
        return (
            <figure className='homeServiceDecor'>
                <img ref={ref} src='./images/decor1.png' alt='Meenatchi Astro'/>
            </figure>
        );
    }

// Notification
    const [notify, setNotify] = useState(false)
    const [notification, setNotification] =useState([])
    const notifyCol = collection(db,"Notification")
    useEffect(()=>{
        const getNotification = async()=>{
            const data = await getDocs(notifyCol)
            setNotification(data.docs.map((doc)=>({...doc.data(), id:doc.id})));
        };

        getNotification();
    },[])
    // const notification = [{
    //     title:'Trulle',
    //     description:"Let's go to trulle",
    //     link:'https://trulle.in'
    // },
    // {
    //     title:'Notification Title',
    //     description:'Small Description about the Notification',
    //     link:''
    // }]
    return(
        <>
            <div className='wrapper'>
                <section className='homeLander'>
                    <aside className='phoneBtn'>
                        <CallBtn/>
                    </aside>
                    <article>
                        <header>
                            <h3>For Your Life Questions & Decisions</h3>
                            <h1>Meenatchi Astro</h1>
                            <h2>Has The Answers</h2>
                            <div className='btnSpacing'>
                                <ActionBtn/>
                            </div>
                        </header>
                        <figure>
                            <img src='./images/decor1.png' alt='Astro' className='landingDecore'/>
                            <img src='./images/meenatchi1.svg' alt='Meenatchi' className='landMeenatchi'/>
                        </figure>
                    </article>
                </section>
                <section className='homeSignCorosal'>
                    <figure>
                        <img src='./images/1.png' alt='sign'/>
                        <img src='./images/2.png' alt='sign'/>
                        <img src='./images/3.png' alt='sign'/>
                        <img src='./images/4.png' alt='sign'/>
                        <img src='./images/5.png' alt='sign'/>
                        <img src='./images/6.png' alt='sign'/>
                        <img src='./images/7.png' alt='sign'/>
                        <img src='./images/8.png' alt='sign'/>
                        <img src='./images/9.png' alt='sign'/>
                        <img src='./images/10.png' alt='sign'/>
                        <img src='./images/11.png' alt='sign'/>
                        <img src='./images/12.png' alt='sign'/>
                    </figure>
                    <figure>
                        <img src='./images/1.png' alt='sign'/>
                        <img src='./images/2.png' alt='sign'/>
                        <img src='./images/3.png' alt='sign'/>
                        <img src='./images/4.png' alt='sign'/>
                        <img src='./images/5.png' alt='sign'/>
                        <img src='./images/6.png' alt='sign'/>
                        <img src='./images/7.png' alt='sign'/>
                        <img src='./images/8.png' alt='sign'/>
                        <img src='./images/9.png' alt='sign'/>
                        <img src='./images/10.png' alt='sign'/>
                        <img src='./images/11.png' alt='sign'/>
                        <img src='./images/12.png' alt='sign'/>
                    </figure>
                </section>
                <section className='homeWelcome'>
                    <main>
                        <header initial='hidden' ref={ref}>
                            <motion.h2
                                initial={{ opacity: 0, y: 10 }}
                                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 1, delay: .3}}
                            >
                                Welcome to MeenatchiAstro.com
                            </motion.h2>
                        </header>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={isVisible ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 1, delay: .5 }}
                        >
                            The virtual home of Smt Meenatchi, a distinguished and renowned astrologer hailing from the vibrant city of Bangalore. 
                            With more than 15 years of dedicated experience and service in the field of astrology, Smt Meenatchi is an epitome of wisdom, 
                            precision, and dedication. She is revered for her expertise and her proficiency in various forms of astrology, including Baby Naming, 
                            Career, Medical, Finance, and Marriage astrology.
                        </motion.p>
                    </main>
                    {/* <figure>
                    <ParallaxProvider>
                        <Parallax
                            id='parallax'
                            rotate={[
                            '0deg',
                            '120deg'
                            ]}
                        >
                            <img className='outer' src='./images/decorOuter.svg' alt='Astro'/>    
                        </Parallax>
                        </ParallaxProvider> 
                        <img className='inner' src='./images/decorInner.svg' alt='Astro'/>
                        </figure>*/}
                    <ParallaxProvider>
                        <SpinningDecor/>
                    </ParallaxProvider>
                </section>
                <Testimonial/>
                <section className='homeService'>
                    <ServiceTab/>
                    <ParallaxProvider>
                        <ParallaxDecor/>
                    </ParallaxProvider>
                </section>
            </div>
            <div className={notification.length>0?'show notificationContainer':'hide'}>
                <div className={notify?'hideIcon notificationIcon':'showIcon notificationIcon'} onClick={()=>setNotify(!notify)}>
                    <img src='../images/notification.svg' alt='notif'/>
                </div>
                <div className={notify?'displayContainer notification':'noDisplayContainer notification'}>
                    <header>
                        <h3>Notification</h3>
                        <img src='../images/close.svg' alt='close' onClick={()=>setNotify(!notify)}/>
                    </header>
                    <div className='notice'>
                        {notification.map((notify)=>(
                            <article>
                                <a href={notify.link}>
                                    <main>
                                        <h4>{notify.title}</h4>
                                        <p>{notify.description}</p>
                                    </main>
                                    <figure >
                                        <img src='../images/notifyArrow.svg' alt='arrow'/>
                                    </figure>
                                </a>
                            </article>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}