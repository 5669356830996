import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_APIKEY,
  authDomain: process.env.REACT_APP_FIRE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIRE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIRE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING,
  appId: process.env.REACT_APP_FIRE_APPID

  // apiKey: "AIzaSyDdH9MQOvdksp-5t6YqhoHuk2ZcnLWEdRc",
  // authDomain: "meenatchiastor.firebaseapp.com",
  // projectId: "meenatchiastor",
  // storageBucket: "meenatchiastor.appspot.com",
  // messagingSenderId: "699655698610",
  // appId: "1:699655698610:web:e62e07d9b3f64ea448f1ab"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);