import { useEffect, useState } from "react"
import "./articleForm.css"
import {v4} from 'uuid';
import { auth, db } from '../config/firebase'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { storage } from "../config/firebase";
import { ref, uploadBytes } from "firebase/storage";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";


export default function ArticleForm(){
    const navigate = useNavigate()
    const location = useLocation()
    const editData = location.state?.articleData
    
    const [user, setUser]=useState(null)
    const [preview, setPreview] = useState(null)
    useEffect(()=>{
        // onAuthStateChanged(auth,(currentUser)=>{
        //     if(currentUser){
        //        setUser(currentUser)
        //     }else{
        //         navigate('/admin/login')   
        //     }
        // })
        if(sessionStorage.getItem('astologin')==='true'){
            setUser(sessionStorage.getItem('user'))
        }else{
            navigate('/admin/login')
        }
    },[])

    const [article, setArticle] = useState({
        title:"",
        content:"",
        image:"",
        imageName:''
    })
    const fieldChange = (e)=>{
        const {name, value} = e.target
        setArticle((predata)=>({
            ...predata,
            [name]:value
        }))
    }
    const uploadImage=(e)=>{
        
        const name = e.target.name
        const imageFile = e.target.files[0];
        setArticle({ ...article, 
            [name]: imageFile,
            imageName:`${v4()}_${imageFile.name}` });

        const reader=new FileReader();
    
        reader.onloadend = () => {
            setPreview(reader.result);
        };
    
        if (imageFile) {
            reader.readAsDataURL(imageFile);            
        }
    }

    useEffect(()=>{
        if(editData){
            setArticle(editData)
        }
    },[editData])
    
    const date = new Date()
    const today = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`

    // Firestore
    const articleRef = collection(db, "Articles")
    const submitArticle = async(e) =>{
        e.preventDefault()
        console.log(article)

        try{
            await addDoc(articleRef, {
                title:article.title,
                content:article.content,
                image:article.imageName,
                date:today,
                author:user,
                stamp:serverTimestamp()
            }).then(docref=>{
                const imageRef = ref(storage, `articleImages/${article.imageName}`)
                uploadBytes(imageRef, article.image).then(()=>{
                    navigate('/admin/article')
                })
            }).catch(err=>{
                console.error(err)
            })
        }catch(err){
            console.error(err)
        }
    }
    return(
        <div className="cms">
            <div className='landingContainer'>
                <aside>
                    {/* <h2>Hi, {user?.email.split('@')[0]}</h2> */}
                    <h2>Hi, {user?.split("@")[0]}</h2>
                    <button onClick={()=>navigate('/admin/dashboard')}>Dashboard</button>
                    <button onClick={()=>navigate('/admin/article')}>Articles</button>
                    <button onClick={()=>(signOut(auth),sessionStorage.setItem('astologin','false'), window.location.reload())}>Log Out</button>
                    <button onClick={()=>navigate('/')}>Go To Site</button>
                </aside>
                <form className="addArtForm" onSubmit={submitArticle}>                        
                    <h1>New Article</h1>
                    <div className="articleInput">
                        <label>Title</label>
                        <input
                        type="text"
                        name="title"
                        value={article.title}
                        onChange={fieldChange}
                        required
                        />
                    </div>
                    <div className="articleInput">
                        <label>Content</label>
                        <textarea
                        name="content"
                        value={article.content}
                        onChange={fieldChange}
                        rows={5}
                        required
                        />
                    </div>
                    <div className="articleInputImage">
                        <input
                            type="file"
                            name="image"
                            onChange={uploadImage}
                            required
                            />
                    </div>                    
                    <div className="articleInput">
                        <input
                        type="submit"
                        value={"Submit"}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}