import './dashboard.css';
import { auth } from '../config/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Link, useNavigate } from "react-router-dom";
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';

export default function Dashboard (){
    const navigate = useNavigate()
    const [user,setUser]=useState(null)
    useEffect(()=>{
        // onAuthStateChanged(auth,(currentUser)=>{
        //     if(currentUser){
        //         setUser(currentUser)            
        //     }else{
        //         navigate('/admin/login')   
        //     }
        // })
        if(sessionStorage.getItem('astologin')==='true'){
            setUser(sessionStorage.getItem('user'))
        }else{
            navigate('/admin/login')
        }
    },[])    
    const [articles, setArticles] = useState([])
    const [clients, setClients] = useState([])
    const [notify, setNotify] = useState([])
    const articleCol = collection(db,"Articles")
    const clientCol = collection(db,"Clients")
    const notifyCol = collection(db,"Notification")
    useEffect(()=>{
        const getArticles = async()=>{
            const data = await getDocs(articleCol)
            setArticles(data.docs.map((doc)=>({...doc.data(), id:doc.id})));
            const ClientData = await getDocs(clientCol)
            setClients(ClientData.docs.map((doc)=>({...doc.data(), id:doc.id})));
            const NotifyData = await getDocs(notifyCol)
            setNotify(NotifyData.docs.map((doc)=>({...doc.data(), id:doc.id})));
        };

        getArticles();
    },[])
    return(
        <div className='cms'>
            <div className='landingContainer'>
                <aside>
                    {/* <h2>Hi, {user?.email.split("@")[0]}</h2> */}
                    <h2>Hi, {user?.split("@")[0]}</h2>
                    <button onClick={()=>(signOut(auth),sessionStorage.setItem('astologin','false'), window.location.reload())}>Log Out</button>
                    <button onClick={()=>navigate('/')}>Go To Site</button>
                </aside>
                <main className='dashContainer'>                    
                    <div className='dashCountContainer'>
                        <div className='dashCount'>
                            <h1>{clients.length<10?`0${clients.length}`:clients.length}</h1>
                            <p>Clients</p>
                        </div>
                        <div className='dashCount'>
                            <h1>{articles.length<10?`0${articles.length}`:articles.length}</h1>
                            <p>Articles</p>
                        </div>
                        <div className='dashCount'>
                            <h1>{notify.length<10?`0${notify.length}`:notify.length}</h1>
                            <p>Notification</p>
                        </div>
                    </div>
                    <div className='dashBtnContainer'>
                        <button onClick={()=>navigate('/admin/clients', {state:{clients:clients}})}>Clients</button>
                        <button onClick={()=>navigate('/admin/article',{state:{articles:articles}})}>Articles</button>
                        <button onClick={()=>navigate('/admin/notifications',{state:{articles:notify}})}>Notifications</button>                        
                    </div>
                </main>
            </div>
        </div>
    )
}