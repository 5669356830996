import { useEffect, useState } from "react"
import "./articleForm.css"
import {v4} from 'uuid';
import { auth, db } from '../config/firebase'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { storage } from "../config/firebase";
import { ref, uploadBytes } from "firebase/storage";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";


export default function NotificationForm(){
    const navigate = useNavigate()
    const location = useLocation()
    const editData = location.state?.articleData
    
    const [user, setUser]=useState(null)
    const [preview, setPreview] = useState(null)
    useEffect(()=>{
        if(sessionStorage.getItem('astologin')==='true'){
            setUser(sessionStorage.getItem('user'))
        }else{
            navigate('/admin/login')
        }
    },[])

    const [article, setArticle] = useState({
        title:"",
        description:"",
        link:"",
    })
    const fieldChange = (e)=>{
        const {name, value} = e.target
        setArticle((predata)=>({
            ...predata,
            [name]:value
        }))
    }    

    useEffect(()=>{
        if(editData){
            setArticle(editData)
        }
    },[editData])        

    // Firestore
    const notifyRef = collection(db, "Notification")
    const submitArticle = async(e) =>{
        e.preventDefault()
        try{
            await addDoc(notifyRef, {
                title:article.title,
                description:article.description,
                link:article.link,
                stamp:serverTimestamp()
            }).then(docref=>{
                navigate('/admin/notifications')
            }).catch(err=>{
                console.error(err)
            })
        }catch(err){
            console.error(err)
        }
    }
    return(
        <div className="cms">
            <div className='landingContainer'>
                <aside>
                    {/* <h2>Hi, {user?.email.split('@')[0]}</h2> */}
                    <h2>Hi, {user?.split("@")[0]}</h2>
                    <button onClick={()=>navigate('/admin/dashboard')}>Dashboard</button>
                    <button onClick={()=>navigate('/admin/notifications')}>Notifications</button>
                    <button onClick={()=>(signOut(auth),sessionStorage.setItem('astologin','false'), window.location.reload())}>Log Out</button>
                    <button onClick={()=>navigate('/')}>Go To Site</button>
                </aside>
                <form className="addArtForm" onSubmit={submitArticle}>                    
                    <div className="articleInput">
                        <label>Title</label>
                        <input
                        type="text"
                        name="title"
                        value={article.title}
                        onChange={fieldChange}
                        required
                        />
                    </div>
                    <div className="articleInput">
                        <label>Description</label>
                        <input
                        type="text"
                        name="description"
                        value={article.description}
                        onChange={fieldChange}                    
                        required
                        />
                    </div>
                    <div className="articleInput">
                        <label>Link</label>
                        <input
                        type="text"
                        name="link"
                        value={article.link}
                        onChange={fieldChange}                    
                        required
                        />
                    </div>                    
                    <div className="articleInput">
                        <input
                        type="submit"
                        value={"Submit"}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}