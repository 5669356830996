import "./footer.css"
import { Link } from "react-router-dom"
import { useState, useEffect } from "react"

export default function Footer(){
    // Check display
    const [mobile, setMobile] = useState(false)
    const navSelect = () =>{
        if(window.innerWidth<768){
            setMobile(true)
        }else{
            setMobile(false)
        }
    }
    useEffect(() => {
        if (window.innerWidth<768){
            setMobile(true)
        }else{
            setMobile(false)
        }
        window.addEventListener('resize', navSelect);
        window.scrollTo(0, 0);
        return () => {
        window.scrollTo(0, 0);
        window.removeEventListener('resize', navSelect);
        };
    }, []);
    return(
        <section className="footerContainer">
            <article className="footercol mainFooter">
                <header>
                    <h1>Meenatchi Astro</h1>
                    <h2>Parinamam, Evoled Astrology Guidance</h2>
                </header>
                <main className={mobile?"hide":"show"}>
                    <h3>Contact Us</h3>
                    <p>Meenakshiastroservices@gmail.com</p>
                    <p>+91 7090736933</p>
                </main>
                <figure className={mobile?"hide":"show"}>
                    <img src="../images/x.svg" alt="X"/>
                    <img src="../images/fb.svg" alt="FB"/>
                </figure>
                <article className={mobile?"show":"hide"}>
                    <h3>Address</h3>
                    <p>Prestige Lake Side Apartment, Bangalore, 560087, India</p>
                </article>
            </article>
            <article className="footercol footerMenu">
                <ul>
                    <li><Link to={'/'}>Home</Link></li>
                    <li><Link to={'/about'}>About</Link></li>
                    <li><Link to={'/service'}>Service</Link></li>
                    <li><Link to={'/article'}>Articles</Link></li>
                    <li><Link to={'/form'}>Consultation Form</Link></li>
                    <li><Link to={'/contact'}>Contact Us</Link></li>    
                </ul>
            </article>
            <article className="footercol">
                <article className={mobile?"hide":"show"}>
                    <h3>Address</h3>
                    <p>Prestige Lake Side Apartment, Bangalore, 560087, India</p>
                </article>
                <main className={mobile?"show":"hide"}>
                    <h3>Contact Us</h3>
                    <p>Meenakshiastroservices@gmail.com</p>
                    <p>+91 7090736933</p>
                </main>
                <figure className={mobile?"show":"hide"}>
                    <img src="../images/x.svg" alt="X"/>
                    <img src="../images/fb.svg" alt="FB"/>
                </figure>
                <article>
                    <div className="termRow">
                        <a href="/terms"> Terms & Condition </a>
                        <a href="/privacypolicy"> Privacy policy </a>
                    </div>
                    <div className="termRow">
                        <a href="/returnpolicy"> Shipping & refund Policy </a>
                        <p> 2023TCS (OPC) PVT. LTD </p>
                    </div>
                </article>
            </article>
        </section>
    )
}