import "./success.css"
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import sha256 from "sha256";
import { db } from "../config/firebase";
import {collection,getDocs, where, addDoc, updateDoc, doc, onSnapshot, query, orderBy, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { CallBtn } from "../components/repeatComp";

export default function Sucess(){
    
    const [loading, setLoading] = useState(true)

    const {merchantTransactionId} = useParams();
    const [data, setData] = useState({})
    const [client, setClient]=useState({})

    const clientRef = collection(db, "Clients")

    const statusUpdate =async(status)=>{
        let id;
        const clientQuery= query((clientRef),where('transationId',"==",merchantTransactionId))
        const client = await getDocs(clientQuery);
        client.forEach((doc)=>{
            id=doc.id
            setClient(doc.data())
        })
        const clientUpdate = doc(clientRef,id)
        await updateDoc(clientUpdate, {paymentStatus:status})
    }
    useEffect(()=>{
        if(merchantTransactionId){
            // const xVerify=sha256(`/pg/v1/status/${merchantId}/${merchantTransactionId}`+salt_key)+'###'+salt_index
            // const headers={
            //     "Content-Type":"application/json",
            //     "X-VERIFY":xVerify,
            //     "X-MERCHANT-ID":merchantId
            // }
            // axios.get(`https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/status/${merchantId}/${merchantTransactionId}`,{headers})
            // .then(res=>{
            //     setData(res.data)
            //     // console.log(res.data)
            //     res.data.code==="PAYMENT_SUCCESS"?statusUpdate("Success"):statusUpdate("Failed")
            // })
            // .catch(err=>console.error(err))

            
            // axios.post(`http://localhost:8801/checkStatus/${merchantTransactionId}`)
            axios.post(`https://express-phonepe-pay.onrender.com/checkStatus/${merchantTransactionId}`)            
            .then(res=>{
                    setData(res.data)
                    setLoading(false)  
                    res.data.code==="PAYMENT_SUCCESS"?statusUpdate("Success"):statusUpdate("Failed")
            })
            .catch(err=>console.log(err))
        }
    },[merchantTransactionId])
    
// console.log(data.code)
    return(
        <>      
        <div className='wrapper successWrapper'>
        <section className='contactUs'>
            <aside className='phoneBtn'>
                <CallBtn/>
            </aside>
            <main className="successMain">
                <header>
                    <h1 className="successNote">Payment Is{data.code==='PAYMENT_SUCCESS'?" Successfully Completed":" Unsuccessful"}</h1>
                </header>
                <p><span> Service:</span> {client.service}</p>
                <p><span> Customer Name:</span> {client.name}</p>
                <p><span> Phone:</span> {client.phone}</p>
                <p><span> Transation ID:</span> {client.transationId}</p>                
            </main>
            <aside className="successAside successNote2">
            {data.code==='PAYMENT_SUCCESS'?(
                <p>We will contact you shortly.<br/> Feel free to reach out to us with any inquiries, consultations, or concerns. We are here to assist you on your astrological journey.<br/>Save the Transation ID for Future Reference</p>
            ):(
                <p>Please Try again. <br/>if Payment is debited, will be returned in 5 working days, Thank you.<br/>Save the Transation ID for Future Reference</p>
            )}
            </aside>                
        </section>  
        {/* <figure className='contactDecor'>
            <img src='./images/decorOuter.svg' alt='Astro'/>
        </figure>           */}
        </div>
        <div className={loading?'estloadingContainer showFlex':'estloadingContainer hide'}>
            <div className='estloadingBG sucessBG'></div>
            <div className='estloading sucess'>
                {/* <img src='../images/loading.png' /> */}
                <p>Loading </p>
                <p className="loadingAnim"></p>
            </div>
        </div>
        </>
    )
}