import { useEffect, useState } from 'react'
import { ActionBtn } from './repeatComp'
import './serviceTab.css'

export default function ServiceTab () {
    const content = [
        {
            title:"Marriage Love Consultation",
            context:"Discover the path to lasting love and marital bliss with our Marriage/Love Consultation services. Our experienced astrologers delve deep into your birth charts, uncovering the cosmic forces that shape your romantic life.",
            image:"./images/Marriage & Love Consaultation.svg"
        },
        {
            title:"Career and Job Consultation",
            context:"Embark on a fulfilling career journey with our Career and Job Consultation services. Our skilled astrologers analyze your astrological charts to unveil your career potential and help you navigate the professional landscape.",
            image:"./images/job.svg"
        },
        {
            title:"Business Consultation",
            context:"Entrepreneurs, unleash the full potential of your ventures with our Business Consultation services. Our expert astrologers provide strategic insights and astrological guidance to enhance your business prospects.",
            image:"./images/buss.svg"
        },
        {
            title:"Health Consultation",
            context:"Prioritize your well-being with our Health Consultation services. Our astrologers analyze your birth charts to identify potential health challenges and offer preventive measures and remedies.",
            image:"./images/health.svg"
        },
        {
            title:"Childbirth & Child Name Consultation",
            context:"Discover the path to lasting love and marital bliss with our Marriage/Love Consultation services. Our experienced astrologers delve deep into your birth charts, uncovering the cosmic forces that shape your romantic life.",
            image:"./images/child.svg"
        },
        {
            title:"Horoscope Printing Consultation",
            context:"Discover the path to lasting love and marital bliss with our Marriage/Love Consultation services. Our experienced astrologers delve deep into your birth charts, uncovering the cosmic forces that shape your romantic life.",
            image:"./images/astro.svg"
        }
    ]

    const [index, setIndex] = useState(0)
    const [tabContent, setTabContent] = useState({
        title:content[index].title,
        context:content[index].context,
        image:content[index].image
    })
    useEffect(()=>{
        setTabContent({
            title:content[index].title,
            context:content[index].context,
            image:content[index].image
        })
    },[index])
    return(
        <>
        <h3 className='serviceTabTitle'>Esteem Service</h3>
        <section className='serviceTab'>
            <figure className='serviceTabCol serviceTabImage'>
                <img src={tabContent.image} alt='Astro'/>
            </figure>
            <main className='serviceTabCol serviceTabContext'>
                <article>
                    <header>
                        <h3>{tabContent.title}</h3>                        
                    </header>
                    <p>{tabContent.context}</p>
                </article>
                <ActionBtn/>
            </main>
            <ul className='serviceTabCol serviceTabList'>
                <li onClick={()=>setIndex(0)} style={index===0?{color:"#FF9A4D"}:null}>Marriage Love Consultation</li>
                <li onClick={()=>setIndex(1)} style={index===1?{color:"#FF9A4D"}:null}>Career and Job Consultation</li>
                <li onClick={()=>setIndex(2)} style={index===2?{color:"#FF9A4D"}:null}>Business Consultation</li>
                <li onClick={()=>setIndex(3)} style={index===3?{color:"#FF9A4D"}:null}>Health Consultation</li>
                <li onClick={()=>setIndex(4)} style={index===4?{color:"#FF9A4D"}:null}>Childbirth & Child Name Consultation</li>
                <li onClick={()=>setIndex(5)} style={index===5?{color:"#FF9A4D"}:null}>Horoscope Printing Consultation</li>
            </ul>
        </section>
        </>
    )
}