import { Link } from 'react-router-dom'
import './navWeb.css'

export default function NavWeb(){
    return(
        <nav className='navWebContainer'>
            <aside>
                <h1>Meenatchi Astro</h1>
                <p>Parinamam: Evolved Astrology Guidance</p>
            </aside>            
            <ul>
                <li><Link to={'/'}>Home</Link></li>
                <li><Link to={'/about'}>About</Link></li>
                <li><Link to={'/service'}>Service</Link></li>
                <li><Link to={'/article'}>Articles</Link></li>
                <li><Link to={'/form'}>Consultation Form</Link></li>
                <li><Link to={'/contact'}>Contact Us</Link></li>    
            </ul>
        </nav>
    )
}