import { useState } from 'react'
import './comp.css'
import { Link } from 'react-router-dom'
import { storage } from '../config/firebase'
import { ref, getDownloadURL } from 'firebase/storage'

export function CallBtn (){
    return(
        <a href='https://api.whatsapp.com/send/?phone=917090736933&text&type=phone_number&app_absent=0' className='actionBtn toCall'>
                <img src='../images/whatsapp.svg' alt='phone'/>
                {/* <a href='tel:+917090736933'>+91 7090736933</a> */}
                <button>
                    +91 7090736933    
                </button>
        </a>
    )
}

export function ActionBtn (){
    return(
        <Link to={'/form'} className='actionBtn toForm'>
            <p>Let's Talk</p>
            <img src='./images/btnArrow.png' alt='Go'/>
        </Link>
    )
}

export function TestimonialCard (props){
    const {context, name, what} = props
    return(
        <main className='testContainer'>
            <p>{context}</p>
            <h3>{name}</h3>
            <h4>{what}</h4>
        </main>
    )
}

export function ServiceCard (props){
    const {context, title, image, index} = props
    return(
        <main className={index%2===0?'serviceContainer serviceDirection':'serviceContainer serviceDirectionrev'}>
            <main>
                <header>
                    <h1>{title}</h1>
                </header>
                <p>{context}</p>
            </main>
            <figure>
                <img src={image} alt='Astro'/>
            </figure>
        </main>
    )
}

export function ArticleCard(props){
    const {title, content, image, date} = props
    const [trancet, setTrancet] = useState(false)
    const [Url, setUrl] = useState('')
    getDownloadURL(ref(storage, `articleImages/${image}`))
    .then(url=>setUrl(url))
    .catch(err=>console.log(err.message))
    return(
        <article className='articalContainer'>
            <figure>
                <img src={Url} alt='Meenatchi'/>
            </figure>
            <main>
                <header>
                    <h1>{title}</h1>
                </header>
                <summary>
                    <p className={!trancet?'truncate':null}>{content}</p>
                    <input 
                    type='checkbox' 
                    name='trancet' 
                    value={trancet} 
                    onChange={()=>setTrancet(!trancet)}
                    />
                </summary>
                <aside>
                    <h3>Share</h3>
                    <div className='asidefooter'>
                        <img src='./images/x.svg' alt='x'/>
                        <img src='./images/fb.svg' alt='fb'/>
                        <p>{date}</p>
                    </div>
                </aside>
            </main>
        </article>
    )
}