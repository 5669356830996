import { useEffect } from 'react'
import { CallBtn } from '../components/repeatComp'
import './contact.css'

export default function Contact (){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);
    return(
        <div className='wrapper contactWrapper'>
            <section className='contactUs'>
                <aside className='phoneBtn'>
                    <CallBtn/>
                </aside>
                <main>
                    <header>
                        <h1>Contact Us</h1>
                    </header>
                    <p className='contactMargin'><span>Registered Address:</span> Prestige Lake Side Apartment, Bangalore, 560087, India</p>
                    <p><span>Phone Number:</span> +91 7090736933</p>
                    <p><span>Email:</span> meenakshiastroservices@gmail.com</p>
                </main>
                <aside>
                    <p>Feel free to reach out to us with any inquiries, consultations, or concerns. We are here to assist you on your astrological journey. Your questions and feedback are invaluable to us. We look forward to hearing from you soon.</p>
                </aside>                
            </section>  
            <figure className='contactDecor'>
                <img src='./images/decorOuter.svg' alt='Astro'/>
            </figure>          
        </div>
    )
}