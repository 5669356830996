import { useState, useEffect } from 'react';
import './navMob.css'
import { Link, useLocation } from 'react-router-dom';

export default function NavMob(){
    const location = useLocation()
    const current_location = location.pathname.substring(1)
    const [menuClick, setMenuClick] = useState(false)
    
    
    return(
        // <div className='nav_container_mob' style={{backgroundColor:navBG, boxShadow:boxShadow}}>
        <nav className='nav_container_mob'>
            <div className='nav_main_mob'>
                <div className='nav_logo'>
                    <Link to={'/'}>
                        <aside>
                            <h1>Meenatchi Astro</h1>
                            <p>Parinamam: Evolved Astrology Guidance</p>
                        </aside> 
                    </Link>
                </div>
                <div className={menuClick?'nav_mob_menu':'dispalyNone'}>
                    <div className={menuClick?'nav_mob_menu_container':"displayNone"}>
                        <Link to={'/'} onClick={()=>setMenuClick(!menuClick)}>
                            <div className={current_location===""?"color-bar":''}></div>
                            <p className={current_location===""?"udLine":''}>Home</p>
                        </Link>                   
                        <Link to={'/about'} onClick={()=>setMenuClick(!menuClick)}>
                            <div className={current_location==="about"?"color-bar":''}></div>    
                            <p className={current_location==="about"?"udLine":''}>About Us</p>
                        </Link> 
                        <Link to={'/service'} onClick={()=>setMenuClick(!menuClick)}>
                            <div className={current_location==="about"?"color-bar":''}></div>    
                            <p className={current_location==="service"?"udLine":''}>Service</p>
                        </Link>       
                        <Link to={'/article'} onClick={()=>setMenuClick(!menuClick)}>
                            <div className={current_location==="about"?"color-bar":''}></div>    
                            <p className={current_location==="article"?"udLine":''}>Article</p>
                        </Link>       
                        <Link to={'/form'} onClick={()=>setMenuClick(!menuClick)}>
                            <div className={current_location==="about"?"color-bar":''}></div>    
                            <p className={current_location==="form"?"udLine":''}>Consultation Form</p>
                        </Link>            
                        <Link to={'/contact'} onClick={()=>setMenuClick(!menuClick)}>
                            <div className={current_location==="contact"?"color-bar":''}></div>    
                            <p className={current_location==="contact"?"udLine":''}>Contact Us</p>
                        </Link>   
                        <div className='Mobbg'>
                            <img src='../images/decor1.png' alt='decore'/>
                        </div>                     
                    </div>                    
                </div>
                <div className={menuClick?'nav_mob_menu_btn menuClicked':'nav_mob_menu_btn '} onClick={()=>setMenuClick(!menuClick)}>
                    <div className={menuClick?'bar right':'bar'}></div>
                    <div className={menuClick?'opaZero':'bar'} style={{transition:'all 1s'}}></div>
                    <div className={menuClick?'bar left':'bar'}></div>
                </div>
            </div>
        </nav>
    );
}