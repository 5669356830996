import { Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';
import NavWeb from './components/navWeb';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Service from './pages/service';
import ConsaltationForm from './pages/form';
import Article from './pages/article';
import LogPage from './cms/login';
import Listing from './cms/listing';
import ArticleForm from './cms/articleForm';
import NotF from './pages/NotFound';
import Sucess from './pay/sucess';
import Callback from './pay/callback';
import Dashboard from './cms/dashboard';
import ClientListing from './cms/clients';
import NavMob from './components/navMob';
import NotificationForm from './cms/notificationForm';
import NotificationMeena from './cms/notificationListing'

import { auth } from './config/firebase';
import {signOut, onAuthStateChanged } from 'firebase/auth';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import ReturnPolicy from './pages/refund';

// Signout
const useSignOutOnClose = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      signOut(auth);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

function App() {
  // To Display NavBar
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split('/')[1]

  // To Choose NavBar
  const [navbar, setNavbar] = useState(<NavWeb/>)
  const navSelect = () =>{
      if(window.innerWidth<768){
          setNavbar(<NavMob/>)
      }else{
          setNavbar(<NavWeb/>)
      }
  }
  useEffect(() => {
    if (window.innerWidth<768){
      setNavbar(<NavMob/>)
    }else{
      setNavbar(<NavWeb/>)
    }
    window.addEventListener('resize', navSelect);
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener('resize', navSelect);
    };
  }, []);

  // Signout
  // useSignOutOnClose()


  return (
    <>
      {pathname!="admin"?navbar:null}
      <Routes>

        {/* Website */}
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/service' element={<Service/>}/>
        <Route path='/form' element={<ConsaltationForm/>}/>
        <Route path='/article' element={<Article/>}/>
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/privacypolicy' element={<Privacy/>}/>
        <Route path='/returnpolicy' element={<ReturnPolicy/>}/>

        <Route path='*' element={<NotF/>}/>

        {/* CMS */}
        <Route path='/admin/login' element={<LogPage/>}/>
        <Route path='/admin/dashboard' element={<Dashboard/>}/>
        <Route path='/admin/article' element={<Listing/>}/>
        <Route path='/admin/article/form' element={<ArticleForm/>}/>
        <Route path='/admin/clients' element={<ClientListing/>}/>
        <Route path='/admin/notifications' element={<NotificationMeena/>}/>
        <Route path='/admin/notification/form' element={<NotificationForm/>}/>
        {/* <Route path='/check' element={<Callback/>}/> */}

        {/* pay */}
        <Route path='/checkout/:merchantTransactionId?' element={<Sucess/>}/>
        
      </Routes>      
      {pathname!="admin"?<Footer/>:null}
    </>
  );
}

export default App;
