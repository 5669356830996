import { useEffect } from 'react';
import { ActionBtn, CallBtn } from '../components/repeatComp'
import { Testimonial } from '../components/testimonial'
import './about.css'

export default function About(){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);
    return(
        <div className='wrapper'>
            <section className='aboutLanding'>
                <aside className='phoneBtn'>
                    <CallBtn/>
                </aside>
                <figure className='aboutMainImage'>
                    <img className='aboutMainMeena' src='./images/meenatchi1.svg' alt='Meenatchi'/>
                    <img className='aboutMainDecor' src='./images/decor1.png' alt='Astro'/>
                </figure>
                <aside className='aboutSubImages'>
                    <div>
                        <figure className='aboutsub one'>
                            <img src='./images/love.png' alt=''/>    
                        </figure>
                        <figure className='aboutsub two'>
                            <img src='./images/carrer.png' alt=''/>       
                        </figure>
                        <figure className='aboutsub three'>
                            <img src='./images/business.png' alt=''/>
                        </figure>
                    </div>
                    <div>
                        <figure className='aboutsub four'>
                            <img src='./images/medical.png' alt=''/>    
                        </figure>
                        <figure className='aboutsub five'>
                            <img src='./images/baby.png' alt=''/>       
                        </figure>
                        <figure className='aboutsub six'>
                            <img src='./images/horoscope.png' alt=''/>
                        </figure>
                    </div>
                </aside>
            </section>
            <section className='aboutContent'>
                <main>
                    <header>
                        <h1>About US</h1>
                    </header>
                    <article>
                        <p>
                        Welcome to MeenatchiAstro.com, the virtual home of Smt Meenatchi, a distinguished and renowned astrologer hailing from the vibrant city of Bangalore. With more than 15 years of dedicated experience and service in the field of astrology, Smt Meenatchi is an epitome of wisdom, precision, and dedication. She is revered for her expertise and her proficiency in various forms of astrology, including  Baby Naming, Career, Medical, Finance, and Marriage astrology.<br/>
                        Smt Meenatchi’s approach to astrology is grounded in the rich traditions of Vedic Astrology with a special focus on Tamilnadu’s distinctive perspectives. She believes in harnessing the ancient knowledge contained within our Vedic scriptures, transforming them into applicable and tangible remedies for modern living. This approach not only makes her remedies more effective but also gives them a deep-rooted spiritual foundation.<br/><br/>
                        But Smt Meenatchi’s journey of knowledge is an ever-evolving process. She is currently researching various intricate and nuanced forms of astrology like  Vedic Astrology, Naadi Astrology, KP Astrology, ALP Astrology, and Vedic Astrology. These forms of astrology, although ancient, contain wisdom and insights that are applicable to our current day-to-day scenarios. Her ongoing research into these fields ensures that the astrological solutions provided are expansive, insightful, and encompass the wisdom of the ancients as well as the needs of the presen<br/>
                        In a testament to her forward-thinking approach, Smt Meenatchi is also exploring the exciting intersections of astrology and artificial intelligence. She is diligently working on leveraging the latest AI models to bring astrological correlations and predictions to the next level. By training these AI models with extensive astrological data, she aims to develop an intelligent system capable of providing even more precise and customized astrological predictions.<br/>
                        At MeenatchiAstro.com, we are committed to bridging the ancient wisdom of the  Universe stars with the conveniences of the modern world. It’s more than just astrology; it’s a journey of self-discovery, a guide for life’s challenges, and a beacon of light in times of uncertainty. With Smt Meenatchi’s guidance and expertise, let’s embark on this journey towards enlightenment together
                        </p>
                    </article>
                </main>
                <aside>
                    <figure>
                        <img src='./images/decor1.png' alt='Astro'/>
                    </figure>
                </aside>
            </section>
            <section className='aboutToLink'>
                <p className='aboutDummy'>For prediction related appointment</p>
                <ActionBtn/>
            </section>
            <section className='aboutTestimonial'>
                <Testimonial/>
            </section>
        </div>
    )
}