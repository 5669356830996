import './conditions.css'

export default function Terms(){
    return(
    <div className='wrapper conditionWrapper'>
        <section>
            <h1>Terms of Service</h1>
            <ol>
                <li>
                    <header>Acceptance of Terms</header>
                    <main>
                        <p><span>1.1.</span> Welcome to MeenatchiAstro (“we,” “our,” or “us”). By accessing or using our website, 
                        meenatchiastro.com, or any of our astrological consultation services, you agree to comply with 
                        and be bound by these Terms of Service. If you do not agree to these terms, 
                        please refrain from using our services.</p>
                    </main>
                </li>
                <li>
                    <header>Services and Consultations</header>
                    <main>
                        <p><span>2.1.</span> MeenatchiAstro offers astrological consultation services for entertainment and personal guidance purposes only. Our services do not replace professional medical, legal, or financial advice.</p>
                        <p><span>2.2.</span> You understand that the astrological information provided is based on interpretations and is subject to individual interpretation. We do not guarantee the accuracy, completeness, or effectiveness of our consultations.</p>
                    </main>
                </li>
                <li>
                    <header>User Registration</header>
                    <main>
                        <p><span>3.1.</span> To access certain services, you may be required to create an account and provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account and password.</p>
                        <p><span>3.2.</span> You agree to notify us immediately of any unauthorized use of your account or any other security breach.</p>
                    </main>
                </li>
                <li>
                    <header>User Conduct</header>
                    <main>
                        <p><span>4.1.</span> You agree not to use our services for any unlawful or prohibited purposes. You shall not engage in any conduct that may disrupt or harm our website or services.</p>
                        <p><span>4.2.</span> You shall not share offensive, abusive, or inappropriate content through our services, including in comments or reviews.</p>
                    </main>
                </li>
                <li>
                    <header>Payment and Fees</header>
                    <main>
                        <p><span>5.1.</span> Some of our services may require payment. You agree to pay all fees and charges associated with your use of these services. Please note that meenatchiastro.com accepts payment under the company name Tuskerrayz Consultation Services (OPC) Private Limited.</p>
                    </main>
                </li>
                <li>
                    <header>Intellectual Property</header>
                    <main>
                        <p><span>6.1.</span> All content on meenatchiastro.com, including text, images, graphics, and logos, is the property of MeenatchiAstro and is protected by intellectual property laws. You may not use, reproduce, or distribute our content without our written consent.</p>
                    </main>
                </li>
                <li>
                    <header>Privacy</header>
                    <main>
                        <p><span>7.1.</span> Your use of our website and services is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information.</p>
                    </main>
                </li>
                <li>
                    <header>Disclaimers and Limitations</header>
                    <main>
                        <p><span>8.1.</span> We provide our services on an “as-is” and “as-available” basis, without warranties of any kind.</p>
                        <p><span>8.2.</span> MeenatchiAstro shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our services.</p>
                    </main>
                </li>
                <li>
                    <header>Termination</header>
                    <main>
                        <p><span>9.1.</span> We reserve the right to terminate or suspend your account and access to our services, at our discretion and without notice, for any violation of these Terms of Service.</p>
                    </main>
                </li>
                <li>
                    <header>Changes to Terms</header>
                    <main>
                        <p><span>10.1.</span> We may update these Terms of Service from time to time. Any changes will be effective upon posting on our website.</p>
                    </main>
                </li>
                <li>
                    <header>Contact Us</header>
                    <main>
                        <p><span>11.1.</span> If you have any questions or concerns about these Terms of Service, please contact us at Meenakshiastroservices@gmail.com.</p>
                    </main>
                </li>
            </ol>
        </section>
        <figure className='termsDecor'>
            <img src='./images/decorOuter.svg' alt='Astro'/>
        </figure>  
    </div>
    )
}