import './listing.css'
import { auth } from '../config/firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { collection, deleteDoc, getDocs, doc, orderBy, query, onSnapshot} from 'firebase/firestore';
import { db } from '../config/firebase';
import { useEffect, useState } from 'react';
import { ref, deleteObject } from 'firebase/storage';
import { storage } from '../config/firebase';


export default function ClientListing (){
    const navigate = useNavigate()
    const [user,setUser]=useState(null)
    useEffect(()=>{
        // onAuthStateChanged(auth,(currentUser)=>{
        //     if(currentUser){
        //         setUser(currentUser)            
        //     }else{
        //         navigate('/admin/login')   
        //     }
        // })
        if(sessionStorage.getItem('astologin')==='true'){
            setUser(sessionStorage.getItem('user'))
        }else{
            navigate('/admin/login')
        }
    },[])    
    const [articles, setArticles] = useState([])
    let index=0
    const [removeDialog, setRemoveDialgo] = useState(false) 
    const [removeArt, setRemoveArt]=useState({
        title:'',
        id:'',
        image:''
    })
    const articleCol = collection(db,"Clients")
    useEffect(()=>{
        const getArticles = async()=>{
            // const data = await getDocs(articleCol)
            // setArticles(data.docs.map((doc)=>({...doc.data(), id:doc.id})));
            const q = query(articleCol, orderBy("stamp", "desc"))

            onSnapshot(q, (snapshot)=>{
                let c = [];
                snapshot.docs.forEach((doc)=>{
                    c.push({...doc.data(), id:doc.id})
                })
                setArticles(c)
            })
        };

        getArticles();
    },[])
    const delArticle =(id, image)=>{
        // const imageRef = ref(storage, `articleImages/${image}`)
        // deleteObject(imageRef)
        // .then(()=>{
        //     const delArt = doc(articleCol, id)
        //     deleteDoc(delArt)
        //     .then(res=>{setRemoveDialgo(false);window.location.reload();})
        //     .catch(err=>(console.log(err.message)))
        // })
        // .catch(err=>(console.log(err.message)))
    }
    

    const removeBox = ()=>{
        return(
            <>
                <div className='removeWindow'>
                    <div className='removeContainer'>
                        <p>Do you want to remove {removeArt.Title}?</p>
                        <div className='removeOption'>
                            <button onClick={()=>delArticle(removeArt.id, removeArt.image)}>Yes</button>
                            <button onClick={()=>setRemoveDialgo(false)}>No</button>
                        </div>
                    </div>
                    <div className='rmBG'></div>
                </div>
            </>
        )
    }
    return(
        <>
            <div className='cms'>
                <div className='landingContainer'>
                    <aside>
                        {/* <h2>Hi, {user?.email.split("@")[0]}</h2> */}
                        <h2>Hi, {user?.split("@")[0]}</h2>
                        <button onClick={()=>navigate('/admin/dashboard')}>Dashboard</button>
                        <button onClick={()=>(signOut(auth),sessionStorage.setItem('astologin','false'), window.location.reload())}>Log Out</button>
                        <button onClick={()=>navigate('/')}>Go To Site</button>
                    </aside>
                    <main>
                        <table>
                            <thead>
                                <tr>                                    
                                    <th style={{width:"10rem"}}>Client Name</th>
                                    <th style={{width:"10rem"}}>Phone Number</th>
                                    <th style={{width:"auto"}}>Service</th>
                                    <th style={{width:"8rem"}}>Payment Id</th>
                                    <th style={{width:"14rem"}}>Payment Status</th>
                                </tr>
                            </thead>
                            <tbody>                                                                
                                {articles.map((article,key)=>{
                                    index +=1
                                    return(
                                        <>
                                            <tr key={key}>
                                                <td>{article.name}</td>
                                                <td>{article.phone}</td>
                                                <td style={{textAlign:"left"}}>{article.service}</td>
                                                <td>{article.transationId}</td>
                                                <td onClick={()=>console.log(article)}>{article.paymentStatus}</td>
                                            </tr>
                                            <tr style={{height:".5rem", borderTop:"2px solid #562500"}}></tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </main>
                </div>
            </div>
            {removeDialog?removeBox():null}
        </>
    )
}