import './conditions.css'

export default function Privacy(){
    return(
    <div className='wrapper privacyWrapper'>
        <section>
            <h1>Privacy Policy</h1>
            <article>
                <header>Introduction</header>
                <p>Welcome to MeenatchiAstro, your trusted source for astrological consultations. 
                    At MeenatchiAstro, we are committed to respecting and protecting your privacy. 
                    This Privacy Policy explains how we collect, use, disclose, and safeguard your 
                    personal information when you visit our website, meenatchiastro.com, or use our 
                    astrological consultation services.</p>
            </article>
            <article>
                <header>Information We Collect</header>
                <p>Personal Information: We may collect personal information when you register for our services 
                    or interact with our website, including but not limited to your name, email address, date of birth, 
                    phone number, and payment information.</p>
            </article>
            <article>
                <header>Usage Information</header>
                <p>We collect information about your interactions with our website and services, such as your IP address, device information, browser type, and pages visited.</p>
            </article>
            <article>
                <header>Cookies</header>
                <p>MeenatchiAstro uses cookies and similar tracking technologies to enhance your browsing experience and analyze website traffic. You can manage your cookie preferences through your browser settings.</p>
            </article>
            <article>
                <header>How We Use Your Information</header>
                <main>
                    <h2>We may use your personal information for the following purposes:</h2>
                    <p>To provide astrological consultation services.</p>
                    <p>To process payments and fulfill orders.</p>
                    <p>To communicate with you regarding consultations and related matters.</p>
                    <p>To send you promotional materials or updates with your consent.</p>
                    <p>To improve our website and services.</p>
                    <p>To comply with legal obligations.</p>
                </main>
            </article>
            <article>
                <header>Disclosure of Your Information</header>
                <main>
                    <h2>We may share your personal information with third parties only for the following purposes:</h2>
                    <p>To process payments.</p>
                    <p>To provide astrological consultation services.</p>
                    <p>To comply with legal obligations.</p>
                    <p>We do not sell, rent, or trade your personal information to third parties for marketing purposes.</p>
                </main>
            </article>
            <article>
                <header>Data Security</header>
                <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of data transmission or storage is completely secure.</p>
            </article>
            <article>
                <header>Your Choices</header>
                <p>You may have the right to access, update, or delete your personal information. Please contact us at [insert contact email] to exercise these rights.</p>
            </article>
            <article>
                <header>Changes to this Privacy Policy</header>
                <p>We may update this Privacy Policy periodically to reflect changes in our practices or for legal reasons. Please review this policy periodically for updates.</p>
            </article>
            <article>
                <header>Contact Us</header>
                <p>If you have any questions or concerns about our Privacy Policy or how we handle your personal information, please contact us at meenakshiastroservices@gmail.com</p>
            </article>
        </section>
        <figure className='termsDecor'>
            <img src='./images/decorOuter.svg' alt='Astro'/>
        </figure>
    </div>
    )
}