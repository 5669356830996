import './listing.css'
import { auth } from '../config/firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { collection, deleteDoc, getDocs, doc} from 'firebase/firestore';
import { db } from '../config/firebase';
import { useEffect, useState } from 'react';
import { ref, deleteObject } from 'firebase/storage';
import { storage } from '../config/firebase';


export default function NotificationsMeena (){
    const navigate = useNavigate();
    const location = useLocation();
    // const articlesState = location.state.articles;
    const [user,setUser]=useState(null)
    useEffect(()=>{
        // onAuthStateChanged(auth,(currentUser)=>{
        //     if(currentUser){
        //         setUser(currentUser)            
        //     }else{
        //         navigate('/admin/login')   
        //     }
        // })
        if(sessionStorage.getItem('astologin')==='true'){
            setUser(sessionStorage.getItem('user'))
        }else{
            navigate('/admin/login')
        }
    },[])


    const [articles, setArticles] = useState([])
    let index=0
    const [removeDialog, setRemoveDialgo] = useState(false) 
    const [removeArt, setRemoveArt]=useState({
        title:'',
        id:''
    })
    const articleCol = collection(db,"Notification")
    useEffect(()=>{
        const getArticles = async()=>{
            const data = await getDocs(articleCol)
            setArticles(data.docs.map((doc)=>({...doc.data(), id:doc.id})));
        };

        getArticles();
    },[])
    const delArticle =(id)=>{
        const delArt = doc(articleCol, id)
        deleteDoc(delArt)
        .then(res=>{setRemoveDialgo(false);window.location.reload();})
        .catch(err=>(console.log(err.message)))
    }

    const removeBox = ()=>{
        return(
            <>
                <div className='removeWindow'>
                    <div className='removeContainer'>
                        <p>Do you want to remove {removeArt.title}?</p>
                        <div className='removeOption'>
                            <button onClick={()=>delArticle(removeArt.id)}>Yes</button>
                            <button onClick={()=>setRemoveDialgo(false)}>No</button>
                        </div>
                    </div>
                    <div className='rmBG'></div>
                </div>
            </>
        )
    }
    useEffect(()=>{
        console.log("removeDialog:",removeDialog)
    },[removeDialog])
    return(
        <>
            <div className='cms'>
                <div className='landingContainer'>
                    <aside>
                        {/* <h2>Hi, {user?.email.split("@")[0]}</h2> */}
                        <h2>Hi, {user?.split("@")[0]}</h2>
                        <button onClick={()=>navigate('/admin/dashboard')}>Dashboard</button>
                        <button onClick={()=>navigate('/admin/notification/form')}>Add Notification</button>
                        <button onClick={()=>(signOut(auth),sessionStorage.setItem('astologin','false'), window.location.reload())}>Log Out</button>
                        <button onClick={()=>navigate('/')}>Go To Site</button>
                    </aside>
                    <main>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width:"5rem"}}>S.No.</th>
                                    <th style={{width:"10rem"}}>Title</th>
                                    <th style={{width:"auto"}}>Description</th>
                                    <th style={{width:"10rem"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr style={{height:"1.5rem"}}></tr>
                            {articles.map((article,key)=>{
                                index +=1
                                return(
                                    <>
                                        <tr key={key}>
                                            <td>{index}</td>
                                            <td style={{textAlign:"left"}}>{article.title}</td>
                                            <td>{article.description}</td>
                                            <td style={{display:"flex", justifyContent:"space-evenly"}}>
                                                <button onClick={()=>{setRemoveArt(article);setRemoveDialgo(true)}}>delete</button>
                                                {/* <button onClick={()=>{console.log("waht")}}>delete</button> */}
                                            </td>
                                        </tr>
                                        <tr style={{height:".5rem", borderTop:"2px solid #562500"}}></tr>
                                    </>
                                )
                            })}
                            </tbody>
                        </table>
                    </main>
                </div>
            </div>
            {removeDialog?removeBox():null}
        </>
    )
}