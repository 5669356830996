import { useState } from 'react'
import { CallBtn } from '../components/repeatComp'
import './form.css'
import { Testimonial } from '../components/testimonial'
import axios from 'axios'
import sha256 from 'sha256'
import uniqid from 'uniqid'
import { useNavigate } from 'react-router-dom'
import { db } from '../config/firebase'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'

export default function ConsaltationForm(){
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState({
        name:'',
        email:'',
        phone:'',
        dob:'',
        hour:'',
        minute:'',
        place:'',
        city:'',
        state:'',
        country:'',
        service:'Marriage and Love',
        terms:false,
        amount:100000
    })

    const fieldChange = (e) =>{
        const {name, value} = e.target
        console.log(name, value)
        if(name==='service'){
            if(value==="class"){
                setDetails((preData)=>({
                    ...preData,
                    [name]:value,
                    amount:500000
                }))
            }else{
                setDetails((preData)=>({
                    ...preData,
                    [name]:value,
                    amount:100000
                }))
            }            
        }else{
            setDetails((preData)=>({
                ...preData,
                [name]:value
            }))
        }
    }
    
    const fieldChangeCheck =(e)=>{
        const {name, checked} = e.target
        if(checked){
            setDetails((preData)=>({
                ...preData,
                [name]:checked
            }))
        }
    }

    const hr =  Array.from({length: 24}, (_, i) => i + 1);
    const min =  Array.from({length: 60}, (_, i) => i + 1);
// Store Client in FireStore
    const date = new Date()
    const today = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`
    const clientRef = collection(db, "Clients")

    
    // const submitForm = async(e) =>{
    //     e.preventDefault() 
                
    //     const payPoint = "/pg/v1/pay"                
    //     const merchantTransactionId = uniqid()
    //     const salt_index = "1"

    //     const url = "https://api-preprod.phonepe.com/apis/pg-sandbox"
    //     const merchan_Id = "PGTESTPAYUAT148"
    //     const salt_key = "046d9f63-bf3b-4b74-9b8e-93121160573e"
    //     const redirectUrl = `http://localhost:3000/checkout/${merchantTransactionId}`
    //     const callbackUrl = "http://localhost:3000/callback"

    //     // const url = "https://api.phonepe.com/apis/hermes"
    //     // const merchan_Id = "TUSKERRAYZONLINE"
    //     // const salt_key = "cf507648-a0e7-4f62-a7db-aee5f758217f"
    //     // const redirectUrl = `https://illustrious-torrone-ec0205.netlify.app/checkout/${merchantTransactionId}`
    //     // const callbackUrl = "https://illustrious-torrone-ec0205.netlify.app/callback"

    //     const payLoad = {
    //         "merchantId": merchan_Id,
    //         "merchantTransactionId": merchantTransactionId,
    //         "merchantUserId": "tru002",
    //         "amount": 10000,
    //         "redirectUrl": redirectUrl,
    //         "redirectMode": "REDIRECT",
    //         "callbackUrl": callbackUrl,
    //         "mobileNumber": "9999999999",
    //         "paymentInstrument": {
    //           "type": "PAY_PAGE"
    //         }
    //       }

    //     const base64PayLoad = btoa(JSON.stringify(payLoad))
    //     // const bufferObj = Buffer.from(JSON.stringify(payLoad), "utf8")
    //     // const base64PayLoad = bufferObj.toString("base64");
        
    //     const xVerify = sha256(base64PayLoad+"/pg/v1/pay"+salt_key)+"###"+salt_index        
    //     const headers = {            
    //             "Content-Type":"application/json",
    //             "X-VERIFY":xVerify,
    //     }
    //     const body ={
    //         request:base64PayLoad
    //     }

    //     await axios.post(`${url}${payPoint}`,body,{headers})
    //     .then(res=>{
    //         try{
    //             addDoc(clientRef,{
    //                 name:details.name,
    //                 email:details.email,
    //                 phone:details.phone,
    //                 dob:details.dob,
    //                 hour:details.hour,
    //                 minute:details.minute,
    //                 place:details.place,
    //                 city:details.city,
    //                 state:details.state,
    //                 country:details.country,
    //                 service:details.service,
    //                 terms:details.terms,
    //                 date:today,
    //                 transationId:res.data.data.merchantTransactionId,
    //                 paymentStatus:"Initiated",
    //                 stamp:serverTimestamp()
    //             }).then(docref=>{
    //                 const PayURL = res.data.data.instrumentResponse.redirectInfo.url
    //                 window.location.replace(PayURL)
    //             }).catch(error=>console.error("firestore:",error))
    //         }catch(error){
    //             console.error(error)
    //         }
    //     })
    //     .catch(err=>console.error("error:",err))
    // }

    const submitCheck = async(e)=>{
        e.preventDefault()   
        setLoading(true)                      
        const body = {
            phone: details.phone,
            amount: details.amount
        }
        axios.post(`https://express-phonepe-pay.onrender.com/checkpay?request=meenatchi`,body)
        // axios.post(`http://localhost:8801/checkpay?request=meenatchi`,body)
        .then(res=>{
            // console.log(res.data.instrumentResponse.redirectInfo.url)            
            try{
                addDoc(clientRef,{
                    name:details.name,
                    email:details.email,
                    phone:details.phone,
                    dob:details.dob,
                    hour:details.hour,
                    minute:details.minute,
                    place:details.place,
                    city:details.city,
                    state:details.state,
                    country:details.country,
                    service:details.service,
                    terms:details.terms,
                    date:today,
                    transationId:res.data.merchantTransactionId,
                    paymentStatus:"Initiated",
                    stamp:serverTimestamp()
                }).then(docref=>{
                    // const PayURL = res.data.data.instrumentResponse.redirectInfo.url
                    window.location.replace(res.data.instrumentResponse.redirectInfo.url)
                }).catch(error=>console.error("firestore:",error))
            }catch(error){
                console.error(error)
            }
        })
        .catch(err=>console.log(err.message))
    }

    return(
        <>        
        <div className='wrapper'>
            <aside className='phoneBtn formPage'>
                <CallBtn/>
            </aside>
            <section className='formContainer'>
                <form onSubmit={submitCheck}>
                    <header>
                        <h1>Consultation</h1>
                    </header>
                    <div className='inputContainer'>
                        <label>Name/பெயர்/ಹೆಸರು*</label>
                        <input
                        type='text'
                        placeholder='Name'
                        name='name'                        
                        value={details.name}
                        onChange={fieldChange}
                        required
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Email Address/மின்னஞ்சல் முகவரி/ಇಮೇಲ್ ವಿಳಾಸ</label>
                        <input
                        type='email'
                        placeholder='sample@gmail.com'
                        name='email'                        
                        value={details.email}
                        onChange={fieldChange}
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Phone/தொலைபேசி எண்/ದೂರವಾಣಿ ಸಂಖ್ಯೆ*</label>
                        <input
                        type='tel'
                        placeholder='+917090736933'
                        name='phone'
                        pattern="\d{10}" 
                        maxlength="10" 
                        minlength="10"                        
                        value={details.phone}
                        onChange={fieldChange}
                        required
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Date of Birth/பிறந்த தேதி/ ಹುಟ್ತಿದ ದಿನ*</label>
                        <input
                        type='date'
                        name='dob'                        
                        value={details.dob}
                        onChange={fieldChange}
                        required
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Time of Birth/பிறந்த நேரம்/ ಹುಟ್ಟಿದ ಸಮಯ *</label>
                        <div className='time'>                            
                            <select  
                                name="hour"                                 
                                value={details.hour}
                                onChange={fieldChange}
                                required
                            >
                                <option value={""} hidden>Hour</option>
                                {hr.map((h)=>(<option value={h-1}>{h-1}</option>))}
                            </select>
                            <select  
                                name="minute"                                 
                                value={details.minute}
                                onChange={fieldChange}
                                required
                            >
                                <option value={""} hidden>Minute</option>
                                {min.map((h)=>(<option value={h-1}>{h-1}</option>))}
                            </select>                            
                        </div>
                    </div>
                    <div className='inputContainer'>
                        <label>Place of Birth/ ಹುಟ್ಟಿದ ಸ್ಥಳ/ பிறந்த இடம்/ ಹುಟ್ಟಿದ ಸ್ಥಳ*</label>
                        <input
                        type='text'
                        name='place'   
                        placeholder='Local'                     
                        value={details.place}
                        onChange={fieldChange}
                        required
                        />
                    </div>
                    <div className='inputContainer'>
                        <div className='placeRow'>
                            <div className='place'>
                                <label>City</label>
                                <input
                                type='text'
                                name='city'
                                placeholder='City'                        
                                value={details.city}
                                onChange={fieldChange}
                                />
                            </div>
                            <div className='place'>
                                <label>State/Province</label>
                                <input
                                type='text'
                                name='state'     
                                placeholder='State'                   
                                value={details.state}
                                onChange={fieldChange}
                                />
                            </div>
                        </div>
                        <div className='placeRow'>
                            <div className='country'>
                                <label>Country</label>
                                <input
                                type='text'
                                name='country'      
                                placeholder='Country'                  
                                value={details.country}
                                onChange={fieldChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='inputContainer'>    
                        <label>Select the Service*</label>                   
                        <select
                        name='service'
                        value={details.service}
                        onChange={fieldChange}
                        required>
                            <option value={"Marriage and Love"}>Marriage and Love Consultation</option>
                            <option value={"Career and Job"}>Career and Job Consultation</option>
                            <option value={"Business"}>Business Consultation</option>
                            <option value={"Health"}>Health Consultation</option>
                            <option value={"Child"}>Childbirth & Child Name Consultation</option>
                            <option value={"Horoscope"}>Horoscope Printing Consultation</option>
                            <option value={"class"}>Astrology Class</option>
                        </select>
                    </div>
                    <div className='inputContainer checkbox'>
                        <input
                        type='checkbox'
                        name='terms'
                        checked={details.terms}
                        onChange={fieldChangeCheck}
                        required
                        />
                        <label>Yes, I agree with the  Privacy Policy and Terms & Conditions*</label>
                    </div>
                    <div className='inputContainer'>
                        <input
                        type='submit'                       
                        value='Submit'
                        />
                    </div>
                </form>  
                <figure>
                    <img src='./images/meenatchi1.svg' alt='Meenatchi'/>    
                </figure>  
            </section>
            <Testimonial/>
        </div>
        <div className={loading?'estloadingContainer showFlex':'estloadingContainer hide'}>
            <div className='estloadingBG'></div>
            <div className='estloading'>
                <img src='../images/loading.png' />
                <p>Do not Referesh... </p>
            </div>
        </div>
        </>
    )
}