import './conditions.css'

export default function ReturnPolicy(){
    return(
    <div className='wrapper conditionWrapper'>
        <section>
            <h1>Cancellation & Refund Policy</h1>
            <p className='refundPAge'>At MeenatchiAstro, a part of TuskerRayz Consultation Services (OPC) private limited, we are committed to providing high-quality astrological consultation services. Please read our cancellation and refund policy carefully.</p>
            <ol>
                <li>
                    <header>Cancellation Policy</header>
                    <p>Once you have booked an astrological consultation with MeenatchiAstro, you may cancel or reschedule your appointment as long as you provide us with at least [insert notice period, e.g., 24 hours] notice before your scheduled consultation time. To cancel or reschedule, please contact our customer support team via email at or WhatsApp at +91 7090736933.</p>
                </li>
                <li>
                    <header>Refund Policy</header>
                    <p>We regret to inform you that MeenatchiAstro does not provide refunds for any consultation services, including those canceled or rescheduled within the notice period. This policy is in place due to the nature of astrological consultations, which involve the time and expertise of our astrologers.</p>
                </li>
                <li>
                    <header>Astrological Consultation Decision</header>
                    <p>During your astrological consultation, our experienced astrologers provide insights and guidance based on their interpretations of astrological charts and patterns. The decisions you make based on this information are entirely your own. MeenatchiAstro does not assume any responsibility for the choices, actions, or decisions you make as a result of the consultation. Astrological consultations are provided for entertainment and personal guidance purposes only.
                    <br/><br/>We encourage you to ask questions, seek clarifications, and engage in a constructive dialogue with our astrologers during your consultation. However, any decisions or actions you take based on the information provided during the consultation are your sole responsibility.
                    <br/><br/>By booking an astrological consultation with MeenatchiAstro, you acknowledge and agree to our cancellation and refund policy, as well as the understanding that the decisions you make during and after the consultation are made independently.
                    <br/><br/>If you have any questions or concerns about our cancellation and refund policy or any other matters, please contact our customer support team at or via WhatsApp at +91 7090736933.
                    <br/><br/>Thank you for choosing MeenatchiAstro for your astrological consultation needs.</p>
                </li>
            </ol>
        </section>
        <figure className='termsDecor'>
            <img src='./images/decorOuter.svg' alt='Astro'/>
        </figure>  
    </div>
    )
}